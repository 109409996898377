@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
    
}



body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
   
}
.btn{
  font-family: 'Roboto', sans-serif;
}
.navbar-nav .dropdown-menu {
  background-color: rgba(82, 82, 81, 1);
 
}
div.carousel-caption{
  font-weight: bold;
 display: block;
  position: absolute;
  right: 0%;
  bottom: 0%;
  left: 0%;
  height: 650px;
  color: white;
 }
div.caption-fotos{
  
  position: absolute;
  top:45%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  
}
h3 span{
  background-color: #c31c4a ;
  
  margin: 0px auto;
}

div.react-reveal.caption-fotos{

    -webkit-animation-fill-mode: both;

            animation-fill-mode: both;
    -webkit-animation-duration: 3000ms !important;
            animation-duration: 3000ms !important;
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    opacity: 1;
    -webkit-animation-name: react-reveal-535699865795487-1;
            animation-name: react-reveal-535699865795487-1;
  
}
.react-parallax{
  height: 100vh;
}
.react-parallax-background-children{
  width: 100%;
}
.megamenu {
  position: static
}

.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%
}


.link{
  font-size: 1.2rem;
  color:white;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-family: 'Roboto', sans-serif;
}
.link:hover{
  color:rgb(207, 132, 33);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-family: 'Roboto', sans-serif;
}
.dropright.nav-item button.dropdown-toggle.btn.btn-secondary  {
  font-size: 1.2rem;
  color: #fff;
  background-color: transparent;
  border-color: transparent;

}
.dropright.nav-item button.dropdown-toggle.btn.btn-secondary:focus {
  color: #fff;
  background-color:transparent ;
  border-bottom: 5px solid rgba(153,198,187,0.9);
  border-radius: 0px;
  box-shadow: none;
}
.dropright.nav-item button.dropdown-toggle.btn.btn-secondary:hover {
  color:rgb(207, 132, 33);
  background-color:white ;
 
}
div.dropright.nav-item{
  margin-left: .65rem;
}

.show.dropdown.nav-item {
  color: #fff;
  background-color: transparent;
 

}


a.dropdown-item{
  font-size: 1.2rem;
  color: white;
}
a.dropdown-item.active, .dropdown-item:active  {
  -webkit-text-decoration-color: rgba(153,198,187,0.9);
          text-decoration-color: rgba(153,198,187,0.9);
  -webkit-text-decoration-style:solid;
          text-decoration-style:solid;
  background-color:transparent ;
  -webkit-text-decoration-line:underline;
          text-decoration-line:underline;
  border-radius: 0px;
 
}
.nav-link{
  font-size: 1.2rem;
}
div.dropdown.nav-item a.dropdown-toggle.nav-link{
  opacity: 1;
  color: white;
  font-family: 'Roboto', sans-serif;
}
div.dropdown.nav-item a.dropdown-toggle.nav-link:hover{
  color:rgb(207, 132, 33);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-family: 'Roboto', sans-serif;
}
.show.dropdown.nav-item > a.dropdown-toggle.nav-link{
  
  background-color:transparent ;
  border-bottom: 5px solid rgba(153,198,187,0.9);
  border-radius: 0px;
  
}
.nav-pills a.nav-link.active{
  background-color:transparent ;
  border-bottom: 5px solid rgba(153,198,187,0.9);
  border-radius: 0px;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,1) !important;
  font-family: 'Roboto', sans-serif;

}
 
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color:rgb(207, 132, 33);
  font-family: 'Roboto', sans-serif;
}

.navbar{
 
  background-color: rgba(0,0,0,0.55);
  color:white;
}
.text-uppercase {
  letter-spacing: 0.08em
}



.relacion{
  
  position: relative;
  height: 0px;
  padding-bottom: 56.22%;
}


.Image-Calecita{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  
  
}

 
.relacion4x3{
  position: relative;
  height: 0px;
  padding-bottom: 75%;
}


.texto-sobre-foto{
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
 
  background-color: rgba(81, 81, 81, 0.33);

}

.formik-font{
  font-family: 'Roboto', sans-serif;
}

.menu-logo{
  margin-left: 30px;
}
  
.footer-titulo1{
  margin-top: 20px;
  color: white;

  font-size: 90%;
  text-align: center;
  display:none;

}
.footer-text{
  text-align: center;
  font-size: 110%;
  color: black;
}

.footer-text-grande{
  
  text-align: center;
  font-size: 110%;
}

.footer-col1{
  justify-content: center;
  align-content: center;
}

.footer-red{
  margin-left: 5px;
}

.footer-redes{
  justify-content: center;
  padding-top: 10px;
  padding-left: 0px;
  margin: auto;
  display: flex;
  /* align-content: center; */
  align-items: center;
}

.footer-margin{
  background-color: rgb(226,214,130,0.75);
  margin-top: 20px;
  text-align: center;
 
}
.logo-footer{
  margin-left: 0px;
}
.footer-center{
  text-align: center;
}
.radio{
  border-radius: 50px;
}
h1,h2, h3,h5{
  font-family: 'Roboto', sans-serif;
    
}
p{
  text-align: justify;
  font-family: 'Roboto', sans-serif;
    
}
.leaflet-routing-container, .leaflet-routing-error {
 display:none
}
div.leaflet-container {
  margin-top: 20px;
  width: 100%;
  height: 50vh;
  position: relative;
  margin-bottom: 20px;
}

.separator {
  height: 4px;
  width: 170px;
  margin: 10px 0px;
  background: #eee;
  position: relative;
}

.separator:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 50px;
  background:rgba(153,198,187,0.9);
  content: "";
}
.texto-ciudad > p{
  margin-bottom: 0rem;
}
.carousel-inner img.foto {
  width: 100%;
  max-height: 525px;
}

@media only screen and (min-width: 1400px) {
  .carousel-caption h3{
    font-size: 2.9rem;
  }
}


@media only screen and (max-width: 1024px) {
  .footer-grande{
      display: none;
  }
 
}

@media only screen and (max-width: 576px) {
  
  .relacion{
    position: relative;
    height: 0px;
    padding-bottom: 75%;
  }
  div.carousel-caption{
    color: white;
  }
  .carousel-caption h3{
    font-size: 1.6rem;
}

  .navbar.fixed-top {
    position: inherit;
    top: 0;
    right: 16px;
    left: 16px;
    z-index: 1030;
}
  .navbar.sticky-top{
    position: -webkit-sticky;
    position: sticky;
  }
}
@media not screen and (max-width: 1024px) {
  .footer-pequenio{
      display: none;
  }

}
